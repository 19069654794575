<template>
  <login-content>
    <div class="logincont">
      <div class="title title-position">{{ userTitle }}</div>
      <div class="warning-tip" v-show="loginErrorMsg">
        <span><svg-icon icon-class="orange-tip"></svg-icon></span>
        <span class="warning-text">{{ loginErrorMsg }}</span>
      </div>
      <div class="com-input" :class="{ error: accountErrorMsg }">
        <input
          type="text"
          placeholder="请输入手机号/用户名"
          v-model="account"
          @keyup.enter="loginHandler"
        />
        <div class="errtip">{{ accountErrorMsg }}</div>
      </div>
      <div class="com-input" :class="{ error: passwordErrorMsg }">
        <input
          :type="isPwdShow ? 'text' : 'password'"
          placeholder="请输入密码"
          v-model="password"
          @keyup.enter="loginHandler"
        />
        <span class="pwd-switch icon-pos" @click="isPwdShow = !isPwdShow">
          <span class="icon" v-show="!isPwdShow"><svg-icon icon-class="pwd-on"></svg-icon></span>
          <span class="icon" v-show="isPwdShow"><svg-icon icon-class="pwd-off"></svg-icon></span>
        </span>
        <div class="errtip">{{ passwordErrorMsg }}</div>
      </div>
      <div class="option-block" v-if="userPermission.remember || userPermission.forget_password">
        <div
          class="check-tip"
          v-if="userPermission.remember"
          :class="{ checked: checked }"
          @click="checked = !checked"
        >
          <span class="check-icon"></span>
          <span>记住登录状态</span>
        </div>
        <div v-else class="empty-block"></div>
        <a
          v-if="userPermission.forget_password"
          class="check-tip forget"
          @click="$router.push($route.meta.inject ? '/inject/findPassword' : '/findPassword')"
          >忘记密码？</a
        >
      </div>
      <div v-else style="height: 16px"></div>
      <slide-check
        status="status"
        :startFun="onMpanelStart"
        :successFun="onMpanelSuccess"
        :errorFun="onMpanelError"
      ></slide-check>
      <button
        class="com-button"
        :class="[showCode && isValid2Login ? 'com-button-primary' : 'com-button-disabled', 'mt-30']"
        @click="loginHandler"
        @keyup.enter="loginHandler"
      >
        登录
      </button>
      <button
        v-if="userPermission.phone"
        class="com-button com-button-switch nomargin"
        @click="$router.replace($route.meta.inject ? '/inject/login/phone' : '/login/phone')"
      >
        手机验证码登录/注册
      </button>
    </div>
  </login-content>
</template>

<script>
import { pattern_user, pattern_phone } from "@/tool/pattern.js";
import { loginUsername, loginPhone } from "@/api/user.js";
import { _debounce } from "@/tool";
import { mapState } from "vuex";

export default {
  data() {
    this.loginHandler = _debounce(this.loginHandlerFn, 1000);
    return {
      account: "",
      password: "",
      checked: false,
      accountErrorMsg: "",
      passwordErrorMsg: "",
      loginErrorMsg: "",
      isPwdShow: false,
      isValid2Login: false,
      showCode: false,
      userTitle: "",
      userPermission: {
        remember: false,
        phone: false,
        forget_password: false
      }
    };
  },
  computed: mapState({ params: (state) => state.meta.params }),
  watch: {
    account: function (val) {
      const isNotValid = !(pattern_user.test(val) || pattern_phone.test(val));
      this.accountErrorMsg = isNotValid ? "请输入有效的手机号/用户名" : "";
      if (!val) {
        this.accountErrorMsg = "";
      }
      this.isValid2Login = (val && this.password && !this.accountErrorMsg) || false;
    },
    password: function (val) {
      if (!val || val) {
        this.passwordErrorMsg = "";
      }
      this.isValid2Login = (val && this.account && !this.accountErrorMsg) || false;
    }
  },
  mounted() {
    console.log(this.$route.meta);
    this.$nextTick(() => {
      this.userPermission =
        this.$store.state.user.permission || JSON.parse(localStorage.getItem("permission"));
      this.userTitle = this.$store.state.user.title || JSON.parse(localStorage.getItem("title"));
    });
  },
  methods: {
    async loginHandlerFn() {
      if (!this.showCode || !this.isValid2Login) return;
      this.$parent.$el.style.height = "auto";

      const loginData = {
        password: this.password,
        "remember-me": this.checked
      };

      // 账号密码登录
      if (pattern_user.test(this.account)) {
        const res = await loginUsername({
          username: this.account,
          ...loginData
        });
        if (res.success) {
          if (this.$route.meta.inject) {
            window.location.replace(decodeURIComponent(this.params.redirect_uri));
            return;
          }
          if (res.data.location === "/") {
            this.$router.replace("/user");
            return;
          }
          window.location.replace(res.data.location);
        } else {
          // this.$message.error(res.message);
          this.loginErrorMsg = res.message;
        }
      }
      // 手机号密码登录
      else if (pattern_phone.test(this.account)) {
        const res = await loginPhone({
          phone: this.account,
          ...loginData
        });
        if (res.success) {
          if (this.$route.meta.inject) {
            window.location.replace(decodeURIComponent(this.params.redirect_uri));
            return;
          }
          if (res.data.location === "/") {
            this.$router.replace("/user");
            return;
          }
          window.location.replace(res.data.location);
        } else {
          // this.$message.error(res.message);
          this.loginErrorMsg = res.message;
        }
      }
    },
    onMpanelStart() {
      if (this.isValid2Login) {
        return true;
      } else {
        if (!this.account) {
          this.accountErrorMsg = "请输入有效的手机号/用户名";
        }
        if (!this.password) {
          this.passwordErrorMsg = "请输入密码";
        }
        return false;
      }
    },
    onMpanelSuccess() {
      this.showCode = true;
      this.$parent.$el.style.height = "310px";
      this.$parent.$el.style.transition = "all 0.5s";
      this.$nextTick(() => {
        this.$parent.$el.style.height = this.$parent.$el.scrollHeight + "px";
      });
    },
    onMpanelError() {
      this.showCode = false;
    }
  }
};
</script>

<style lang="less" scoped>
.icon-pos {
  position: absolute;
  right: 16px;
  bottom: 9px;
}
.option-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.empty-block {
  width: 10px;
  height: 48px;
}
.mt-30 {
  margin-top: 30px;
}
</style>
